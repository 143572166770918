import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import Realty1 from "../../../images/uploads/realtyexperts/reality1.png"
import Reality2light from "../../../images/uploads/realtyexperts/reality2light.png"
import Reality2dark from "../../../images/uploads/realtyexperts/reality2dark.png"
import Realty3 from "../../../images/uploads/realtyexperts/reality3.png"
import Realty4 from "../../../images/uploads/realtyexperts/reality4.png"
import Realty5 from "../../../images/uploads/realtyexperts/reality5.png"
import Realty6 from "../../../images/uploads/realtyexperts/reality6.png"
import Realty7 from "../../../images/uploads/realtyexperts/reality7.png"
import Realty8 from "../../../images/uploads/realtyexperts/reality8.png"
import Realty9 from "../../../images/uploads/realtyexperts/reality9.png"
import Realty10 from "../../../images/uploads/realtyexperts/reality10.png"
import Rels1 from "../../../images/uploads/realtyexperts/rels1.png"
import Rels2 from "../../../images/uploads/realtyexperts/rels2.png"
import Rels3 from "../../../images/uploads/realtyexperts/rels3.png"
import Rels4 from "../../../images/uploads/realtyexperts/rels4.png"
import Rels5 from "../../../images/uploads/realtyexperts/rels5.png"
import Rels6 from "../../../images/uploads/realtyexperts/rels6.png"
import Rels7 from "../../../images/uploads/realtyexperts/rels7.png"
import Rels8 from "../../../images/uploads/realtyexperts/rels8.png"
import Rels9 from "../../../images/uploads/realtyexperts/rels9.png"
import Rels10 from "../../../images/uploads/realtyexperts/rels10.png"
import Rels11 from "../../../images/uploads/realtyexperts/rels11.png"
import Rels12 from "../../../images/uploads/realtyexperts/rels12.png"

import addcs1 from "../../../images/uploads/decodeup-contemporary-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-cwatch-additional-case-study.png"

const RealtyExperts = props => {

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang

  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "realtyexperts"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-3-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Real Estate & Home for Sale
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Real Estate Buy, Sell & Rent Platform
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  For this real estate website we discussed several goals with
                  our client.From this point we researched the industry, found
                  what home buyers and sellers need most and noted the most
                  important features. This included options for comparing
                  properties to find the best place to live, market reports,
                  photo gallery, property details and owner information.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Matin T
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      USA
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design,
                      Product management, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, JS
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Real Estate
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="mx-auto"
            src={Realty1}
            alt="Realty Experts Decodeup Technologies"
          />
        </section>

        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-rea mx-auto">
            <div className="flex flex-wrap md:flex-nowrap md:gap-16 lg:gap-20 xl:gap-1-44">
              <div className="mb-3 md:mb-6-6 w-full md:w-1/3 ">
                <h2 className="pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Everything you need to buy, sell and rent
                </h2>
              </div>
              <div className="w-full md:w-2/3">
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Realty Experts is Florida-based, focused on propoerties within
                  the US state. Listings originate from the MLS platform and
                  required connection to the database to ensure all information
                  was accurate and current.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Search features included filters by city, neighborhood, zip
                  code, MLS number, address, property type, price and features,
                  such as number of bedrooms or bathrooms. Advanced search
                  options allow end users to pinpoint amenities, keywords and
                  features such as A/C, square footage and more.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The most attractive and helpful feature is the search results
                  page, which displays relevant properties on a Google map with
                  markers so users can visually see where all the matching
                  properties are located.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  You can also save your favorite homes and choose to receive
                  email notifications about price changes or homes that are no
                  longer available.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  All of these features required custom coding in addition to
                  custom authentication to require registration for users to
                  access data.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* intro bold section */}
        <section className="mb-0 mms:mb-20 xl:mb-1-40 overflow-x-hidden">
          <div className="container container-rea mx-auto">
            <div className="lg:pt-2-20 relative ">
              <div className="py-8 px-5 lg:py-20 lg:pl-10 rel-blue">
                <h3 class="lg:w-3/5 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                  Developing this website was not easy. There were numerous
                  requirements and demands on our team to produce a
                  fully-functioning dynamic website. Like all projects, we faced
                  problems and obstacles along the way. However, we are prepared
                  for the most complex websites and have a talented team of
                  developers prepared to create custom solutions.
                </h3>
              </div>
              <div className="hidden lg:block absolute bottom-0 -right-10">
                <img
                  className="mx-auto rellight"
                  src={Reality2light}
                  alt="Realty Experts Decodeup Technologies"
                />
                <img
                  className=" mx-auto reldark"
                  src={Reality2dark}
                  alt="Realty Experts Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Challenge section section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 overflow-x-hidden">
          <div className="container container-rea mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 lg:gap-16 xl:gap-20 ">
              <div className="w-full sm:w-5/20 flex gap-4 flex-col mms:flex-row sm:flex-col">
                <img
                  className="w-full mms:w-1/2 sm:w-full lg:mb-10 xl:mb-16 mx-auto max-w-4-00"
                  src={Realty3}
                  alt="Realty Experts Decodeup Technologies"
                />
                <img
                  className="w-full mms:w-1/2 sm:w-full mx-auto max-w-4-00"
                  src={Realty4}
                  alt="Realty Experts Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-5/65">
                <h2 className="mb-3 md:pb-4 xl:mb-6-6 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We are proud of the intricate details and demands of this
                  project that our team worked so tirelessly on.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  For this website, we needed to connect to the MLS database to
                  create the listings on the Realtye. Experts site. The MLS
                  numbers are unique and represent each property, making it
                  possible to sort and filter homes on the frontend of the site.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Individual property pages include details like images, rooms,
                  square footage, features, descriptions and additional
                  information.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Admins need the ability to manage property details and add or
                  remove listings.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  In addition to the complexity of creating customizable
                  listings we needed to find a way to create print
                  advertisements for properties. This includes flyers and assets
                  to share to social media sites.
                </p>
                <p className="mb-2 lg:mb-3 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-bold">
                  Additional features:
                </p>
                <ul className="list-disc">
                  <li className="ml-4 mb-2 lg:mb-3 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Request property tours
                  </li>
                  <li className="ml-4 mb-2 lg:mb-3 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Save properties to user accounts
                  </li>
                  <li className="ml-4 mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Display advanced property details such as price history,
                    rooms, etc
                  </li>
                </ul>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  We also were tasked with creating a global search feature so
                  sellers and buyers can look for homes based on various
                  location settings.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Challenge image section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 overflow-x-hidden">
          <div className="container container-rea mx-auto">
            <img
              className="mx-auto"
              src={Realty5}
              alt="Realty Experts Decodeup Technologies"
            />
          </div>
        </section>

        {/* solutions */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-rel mx-auto">
            <div className="w-full mb-6 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            <div className="mb-10 md:mb-0 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="w-full sm:w-3/5">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Reliable info hub for real estate
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  To meet the needs of our client, we started with a planning
                  flow to detail each feature of the website. From this point,
                  we began building and designing a site that was reliable for
                  all users.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The landing page is anchored by contemporary design elements
                  that are easy to access. We created a master list of
                  categories that are simple to navigate and clearly visible.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Another solution was to create a search module that
                  accommodated the need for users to search properties by
                  address, area, MLS number, etc. Our custom login matches based
                  on words or parameters and displays on an interactive results
                  page. Whether the search results in a specific entry, list of
                  attributes or map, users can find the home of their dreams.
                </p>
              </div>
              <div className="w-full sm:w-2/5 max-w-4-64 mx-auto">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Realty6}
                  alt="Realty Experts Decodeup Technologies"
                />
              </div>
            </div>
            <div className="mb-10 md:mb-0 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="sm:order-2 w-full sm:w-3/5">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Intricate admin features
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Our team crafted a property management system for admin that
                  allows them to edit and manage listings.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  For the MLS platform we implemented a script to manage data
                  and organized information in chunks. We also made extensive
                  adjustments to the property detail page so users can find
                  exactly what they're looking for.
                </p>
              </div>
              <div className="sm:order-1 w-full sm:w-2/5 max-w-4-64 mx-auto">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Realty7}
                  alt="Realty Experts Decodeup Technologies"
                />
              </div>
            </div>
            <div className="mb-10 md:mb-0 flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16 items-center">
              <div className="w-full md:w-3/5">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Efficient Functionality
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The real estate market is saturated with websites helping
                  people find places to live. We wanted to ensure that this site
                  attracted and retained users, so we created a simple process
                  where all users can find exactly the information they're
                  looking for in an easy-to-read layout.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  DecodeUp successfully overcame each challenge to create a
                  beautiful site.
                </p>
              </div>
              <div className="w-full sm:w-2/5 max-w-4-64 mx-auto">
                <img
                  className="mx-auto w-full h-full object-cover "
                  src={Realty8}
                  alt="Realty Experts Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* typographuy section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-12">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                Typeface & Colors
              </h2>
            </div>
            <div className="flex justify-between flex-wrap md:flex-nowrap md:gap-16">
              <div className="w-1/3 ">
                <img
                  className="rellight"
                  src={Realty9}
                  alt="Realty Experts Decodeup Technologies"
                />
                <img
                  className="reldark"
                  src={Realty10}
                  alt="Realty Experts Decodeup Technologies"
                />
              </div>
              <div className="mt-6 md:mt-0 w-full max-w-9-12">
                <div className="mb-6 xl:mb-16 flex flex-wrap 2md:flex-nowrap gap-4 md:gap-10 xl:gap-16">
                  <div className="2md:w-5/20 lg:w-1/2 rel-play">
                    <h4 className="text-term-primary">Playfair Display</h4>
                    <h5 className="text-term-primary opacity-70">
                      ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    </h5>
                    <h6 className="text-term-primary opacity-70">
                      abcdefghijklmnopqrstuvwxyz
                    </h6>
                  </div>
                  <div className="2md:w-5/20 lg:w-1/2 rel-lato">
                    <h4 className="text-term-primary">Lato</h4>
                    <h5 className="text-term-primary opacity-70">
                      ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    </h5>
                    <h6 className="text-term-primary opacity-70">
                      abcdefghijklmnopqrstuvwxyz
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 md:mt-10 xl:mt-16 w-full flex flex-wrap">
              <div className="p-4 lg:p-8 w-1/2 md:w-1/4 rel-colo-box-1">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-center">
                  #009AC7
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 md:w-1/4 rel-colo-box-2">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-center">
                  #42413f
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 md:w-1/4 rel-colo-box-3">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-center">
                  #827E7A
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 md:w-1/4 rel-colo-box-4">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-center">
                  #FFFFFF
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Result */}
        <section className="pt-20 pb-2-20 md:pb-2-86 xl:pb-4-80 rel-result-sec">
          <div className="container container-cor mx-auto">
            <div className="md:w-3/5">
              <h2 className="mb-4 lg:mb-6-6 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal ">
                We help clients to successfully build and manage the backend of
                their websites and we craft gorgeous layouts for frontend users.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Agents, buyers and sellers alike rave about Realty Experts.
              </p>
              <div className="mt-6 w-full">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  When you choose DecodeUp, you're choosing a team that's
                  committed to winning and who always delivers more than what
                  you paid for.
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="-mt-1-59 mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-rea mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels1}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels2}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels3}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels4}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels5}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels6}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels7}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels8}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels9}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels10}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels11}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl"
                    src={Rels12}
                    alt="Realty Experts Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-fra mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}
        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/dental-health-care-website"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Dental Health Care Website
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs1}
                      alt="Contemporary Dentistry Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/truck-drivers-security-app"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Truck Driver's Security App
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="C-watch Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default RealtyExperts

export const pageQuery = graphql`
query realtyexpertspagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/real-estate-buy-sell-rent-platform.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;